<template>
  <!-- 宜享花专用进件页面 -->
  <div class="container" ref="container" v-cloak>
    <!-- 顶部借款金额 -->
    <div class="container-top">
      <div class="u-flex u-row-between">
        <div class="u-flex u-col-top">
          <img src="../assets/images/icon_check_mark.png" />
          <div class="u-flex-col">
            <p class="container-top-title">借款订单已生成</p>
            <span>首次借款需完成以下信息补充</span>
          </div>
        </div>
        <div class="u-flex-col u-text-right">
          <p>借款金额(元)</p>
          <h4 class="container-top-amount">{{ form.loanAmount }}</h4>
        </div>
      </div>
    </div>

    <!-- 注册表单 -->
    <div class="register-form">
      <!-- 真实姓名 -->
      <div class="register-form-item mb0" ref="realName">
        <van-cell-group>
          <van-field
            label="真实姓名"
            v-model.trim="form.realName"
            class="inp-elem"
            placeholder="请输入您的真实姓名"
            @blur="handleBlur(0)"
            clearable
            input-align="right"
          />
        </van-cell-group>
      </div>

      <!-- 身份证号 -->
      <div class="register-form-item" ref="idCard">
        <van-cell-group>
          <van-field
            label="身份证号码"
            v-model.trim="form.idCard"
            maxlength="18"
            class="inp-elem"
            placeholder="请输入您的身份证号"
            @blur="handleBlur(1)"
            clearable
            input-align="right"
          />
        </van-cell-group>
      </div>

      <!-- 芝麻信用分 -->
      <div class="register-form-item border-none" ref="credit">
        <h4>芝麻信用分</h4>
        <div class="inp-elem tags">
          <van-row :gutter="5">
            <van-col
              v-for="(item, index) in formItemData.credit"
              :key="index"
              :class="item.active ? 'active' : ''"
              span="8"
              @click="handleChooseCreditCredit(index)"
            >
              <div class="u-flex u-col-center u-row-center">
                {{ item.dictLabel }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>

      <!-- 资产信息(可多选) -->
      <div class="register-form-item mb0">
        <h4>资产信息(可多选)</h4>
        <div class="inp-elem tags">
          <van-row :gutter="5">
            <van-col
              v-for="(item, index) in formItemData.creditType"
              :key="index"
              :class="item.active ? 'active' : ''"
              span="8"
              @click="handleSelectCreditInfo(index)"
            >
              <div class="u-flex u-col-center u-row-center">
                {{ item.dictLabel }}
              </div>
            </van-col>
          </van-row>
        </div>
      </div>

      <!-- 贷款申请城市 -->
      <div class="register-form-item">
        <van-cell-group>
          <van-field
            label="贷款申请城市"
            v-model="addressPositionName"
            readonly
            class="inp-elem"
            input-align="right"
            :border="false"
          >
            <template #button>
              <span @click="handleClickPosition">修改</span>
            </template>
          </van-field>
          <p class="citys-tips">
            准确的贷款申请城市将决定我们是否能为您提供贷款服务，请按您所在地如实填写
          </p>
        </van-cell-group>
      </div>
    </div>

    <!-- 确认额度 -->
    <div class="btn-con">
      <van-button
        class="btn"
        type="default"
        @click="handleSubmit"
        :loading="loading"
        loading-type="spinner"
      >
        确认额度
      </van-button>
    </div>

    <!-- 协议 -->
    <van-checkbox
      v-model="checked"
      checked-color="#3563FA"
      icon-size="0.3rem"
      class="protocols u-flex u-col-top"
    >
      我已阅读并同意
      <small @click.stop="showProp('xxsq')" class="protocols-title">
        《个人信息共享授权书》</small
      >
      <small @click.stop="showProp('zqgz')" class="protocols-title">
        《知情告知书》
      </small>
    </van-checkbox>

    <!-- 热线电话 -->
    <p class="tel-con u-text-center">
      <van-icon size="0.22rem" color="#E71A0F" name="phone-o" />
      <a href="tel:4001131190">客服热线: 400-1030-615</a>
    </p>
    <!-- 底部风险提示 -->
    <div class="bottom u-text-center">
      贷款有风险，借款需谨慎<br />
      综合年化6%-24%，最终结果以审批为准<br />
      请根据个人能力合理贷款，理性消费<br />
    </div>

    <!-- 定位城市下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        :columns="areaList"
        @change="onCityChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      >
        <div slot="title" class="picker-title u-text-center">
          <p>请选择定位城市</p>
          <p class="pa">
            准确的贷款申请城市将决定我们是否能为您提供贷款服务，请按您所在地如实填写
          </p>
        </div>
      </van-picker>
    </van-popup>

    <!-- 提交进件信息注册的我已知晓下一步的弹窗 -->
    <Agreement
      @handleGoOn="handleGoOn"
      :incomingShow="incomingShow"
      :sendData="sendData"
      :form="form"
      :applyTime="applyTime"
      :companys="companys"
      :companyAlias="companyAlias"
      :productId="productId"
      :applyId="applyId"
    />

    <!-- 《个人信息共享授权书》和《知情告知书》局部弹窗 -->
    <van-popup
      v-model="showProtocol"
      round
      :style="{
        width: '80%',
        height: '70%',
      }"
      @close="handleClose"
    >
      <div class="show-protocol-con">
        <h4 class="u-text-center">{{ loaclTitle }}</h4>
        <div class="show-protocol-info protocol-content" v-html="showContent" />
        <div
          class="show-protocol-btn u-text-center"
          @click="showProtocol = false"
        >
          关闭
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import myBMap from "../utils/myBMap"; // 引入百度地图
import AMap from "AMap"; //在页面中引入高德地图
import { setEncryption } from "@/utils/encrypt"; //引入加密文件
import * as API_Common from "@/api/common.js";
import Agreement from "../components/Agreement";
import { agreementNext } from "@/mixins/agreementNext.js";
export default {
  name: "RegisterEntryTl2",
  mixins: [agreementNext],
  data() {
    return {
      positionStatus: false, // 定位是否成功，默认false
      positionCityStatus: false, // 百度定位中是否包含城市信息(如果不包含也相当于定位失败)
      provinceArr: undefined, // 百度定位中是否包含城市信息(如果不包含也相当于定位失败)-集合中是对应省的一条数据
      checked: false, // 协议勾选
      address: {
        // 定位地址
        province: "", // 省
        city: "", // 市
      },
      areaList: [
        // 自定义省数据二级结构
        { values: [] },
        { values: [] },
      ],
      showPickerCity: false, // 省市联动弹窗状态
      form: {
        // 提交表单
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount, // 借款金额
        loanPeriod:
          parseInt(this.$route.query.period) === 9
            ? 12
            : parseInt(this.$route.query.period), // 借款期限(宜享花传入的期限可能为9期，为了兼容咱们自己的借款期限(目前支持3 6 12 24)的情况如果宜享花传入的期数为9强制修改为12期 )
        realName: "", // 姓名
        idCard: "", // 身份证号码
        credit: "", // 芝麻分
        assets: [], // 个人资产
        gpsCityName: "", // 省市展示名称
        cityId: "", // 城市ID
        provinceId: "", // 省ID
      },
      formItemData: {
        credit: [
          // 芝麻分集合
          { dictValue: "700以上", dictLabel: "700以上", active: false },
          { dictValue: "650-700", dictLabel: "650-700", active: false },
          { dictValue: "600-650", dictLabel: "600-650", active: false },
        ],
        creditType: [
          // 个人资产集合
          { dictValue: 1, dictLabel: "有房" },
          { dictValue: 2, dictLabel: "有车" },
          { dictValue: 3, dictLabel: "有公积金" },
          { dictValue: 4, dictLabel: "有社保" },
          { dictValue: 5, dictLabel: "有商业保险" },
          { dictValue: 6, dictLabel: "企业主" },
        ],
      },
      regTest: {
        // 正则校验
        regIdcard:
          /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 身份证号码正则校验
      },
      incomingShow: false, // 提交进件信息弹窗
      xxsq: "", // 个人信息共享授权书
      // xxsqHeightFlag: true, // 个人信息共享授权书局部内容是否展开状态
      zqgz: "", // 知情告知书
      // zqgzHeightFlag: true, // 知情告知书局部内容是否展开状态
      showContent: "", //  // 个人信息共享授权书 和 知情告知书 局部弹窗详细内容
      showProtocol: false, // 个人信息共享授权书 和 知情告知书 局部弹窗隐藏显示
      // tdgrxx: "", //特定机构个人信息授权
      // tdgrxxHeightFlag: true, //特定机构个人信息授权是否展开状态
      // tdyszc: "", //特定机构知情告知书
      // tdyszcHeightFlag: true, // 特定机构知情告知书是否展开状态
      tdArea: "", // 特定机构的地理位置信息
      isSpecificInstitution: false, //是否需要展示特定机构的协议
      companys: "", // 公司名称集合
      companyAlias: "", // 公司别名
      loaclTitle: "", // 局部协议弹窗顶部title,
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      applyId: "", // 进件申请ID
      applyTime: "", // 进件申请时间
      hasPushSuccess: undefined, // 是否推送成功
      jwt: this.$route.query.jwt, // 登录字符串
      env: process.env.NODE_ENV, // 是否是线上环境
      loading: false, //确认额度按钮点击状态
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
      workWeixinUrl: this.$route.query.workWeixinUrl, // 跳转微信地址url
      isTail: undefined, // 是否是兜底 1-是 0-否
      redirectUrl: "", // 重定向链接
      productId: null, // 产品id
      sendData: null,
    };
  },
  components: {
    Agreement,
  },
  mounted() {
    this.checkPosition(); // 获取是否支持定位
    this.getProtocol(); // 获取个人信息共享授权书 和 知情告知书 协议内容

    // vuex中存储登录状态
    this.$nextTick(() => {
      this.$store.dispatch("setJwt", this.jwt);
    });
  },
  computed: {
    addressPositionName() {
      // 顶部定位名称
      if (this.positionStatus) {
        return `${this.address.province} ${this.address.city}`;
      } else {
        if (this.form.gpsCityName) {
          return this.form.gpsCityName;
        } else {
          return "定位失败";
        }
      }
    },
  },
  methods: {
    // 获取定位
    checkPosition() {
      // 生产环境(使用高德定位)
      if (this.env === "production") {
        this.initAMap();
      } else {
        // 非生产环境(使用百度定位)
        this.initBMap();
      }
    },

    // 初始化高德地图定位
    initAMap() {
      const that = this;
      AMap.plugin("AMap.Geolocation", function () {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认：true
          timeout: 5000, // 设置定位超时时间，默认：无穷大
        });

        // 获取用户当前的精确位置信息(经纬度)
        geolocation.getCurrentPosition(function (status, result) {
          // 如果成功
          if (status === "complete") {
            // result.position为地址的经纬度(数组[x, y])
            let longitudeAndLatitude = result.position;
            // 逆向地理反查
            that.getLngLatLocation(longitudeAndLatitude);
          } else {
            that.$toast.fail("定位失败，请手动选择城市");
            that.manualPosition();
          }
        });
      });
    },

    // 逆向地理编码查询地理位置详细信息
    getLngLatLocation(longitudeAndLatitude) {
      let that = this;

      AMap.plugin("AMap.Geocoder", function () {
        let geocoder = new AMap.Geocoder({
          // 是否批量查询batch=true为批量查询,batch=false为单点查询，batch=false时即使传入多个点也只返回第一个点结果
          // 配置信息详见https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address/#m_AMap.Geocoder
          batch: false,
        });

        geocoder.getAddress(longitudeAndLatitude, function (status, data) {
          // 逆向地理反查成功
          if (status === "complete" && data.info === "OK") {
            that.positionStatus = true;
            let addComp = data.regeocode.addressComponent;

            that.address.province = addComp.province; //返回当前省份
            that.address.city = addComp.city; //返回当前城市
            that.form.gpsCityName = `${that.address.province} ${that.address.city}`;
          } else {
            // 逆向地理反查失败
            that.$toast.fail("定位失败，请手动选择城市");
            that.manualPosition();
          }
        });
      });
    },

    // 初始化百度地图定位
    initBMap() {
      myBMap.init().then((BMap) => {
        let that = this;
        let geolocation = new BMap.Geolocation();
        let gc = new BMap.Geocoder();

        geolocation.enableSDKLocation(); // 开启SDK辅助定位
        // 创建百度地理位置实例，代替 navigator.geolocation
        geolocation.getCurrentPosition(
          function (r) {
            if (r) {
              /*
              关于this.getStatus()返回状态码示例说明
              BMAP_STATUS_SUCCESS 检索成功。对应数值“0”
              BMAP_STATUS_CITY_LIST 城市列表。对应数值“1”
              BMAP_STATUS_UNKNOWN_LOCATION 位置结果未知。对应数值“2”
              BMAP_STATUS_UNKNOWN_ROUTE 导航结果未知。对应数值“3”
              BMAP_STATUS_INVALID_KEY 非法密钥。对应数值“4”
              BMAP_STATUS_INVALID_REQUEST 非法请求。对应数值“5”
              BMAP_STATUS_PERMISSION_DENIED 没有权限。对应数值“6”
              BMAP_STATUS_SERVICE_UNAVAILABLE 服务不可用。对应数值“7”
              BMAP_STATUS_TIMEOUT 超时。对应数值“8”
            */
              if (this.getStatus() === 0) {
                let pt = r.point;
                that.positionStatus = true;

                // 逆地址解析
                gc.getLocation(pt, function (rs) {
                  let addComp = rs.addressComponents;

                  // 如果没获取到市信息也当成定位失败处理(此时拿百度地图返回的省从集合中筛选出对应的省ID，去查询城市列表)
                  if (!addComp.city) {
                    that.positionCityStatus = true;
                    r.address.city = "";

                    API_Common.getRegions(0).then((res) => {
                      if (200 === res.code) {
                        that.provinceArr = res.data.filter(
                          (i) => i.localName === r.address.province
                        );
                        that.manualPosition();
                      }
                    });
                  } else {
                    that.address.province = addComp.province; //返回当前省份
                    that.address.city = addComp.city; //返回当前城市
                    that.form.gpsCityName = `${that.address.province} ${that.address.city}`;
                  }
                });
              } else {
                that.$toast.fail("定位失败，请手动选择城市");
                that.manualPosition();
              }
            }
          },
          function () {
            that.$toast.fail("定位失败，请手动选择城市");
            that.manualPosition();
          },
          { province: "baidu" }
        );
      });
    },

    // 手动选择定位
    manualPosition() {
      this.positionStatus = false;
    },

    // 监听定位城市栏点击
    handleClickPosition() {
      // 如果百度地图没返回城市数据那么只允许用户去筛选定位出来的省份下面的城市，不允许用户操作除定位省以外的省份
      if (this.positionCityStatus) {
        this.getAreaNotCity(this.provinceArr, 0);
      } else {
        this.getArea(0, 0);
      }
      this.showPickerCity = true;
    },

    // 监听表单blur
    handleBlur(index) {
      switch (index) {
        case 0: // 姓名输入框失去光标
          if (this.form.realName === "") {
            this.$toast("请输入正确的姓名");
          }
          break;
        case 1: // 身份证输入框失去光标
          if (this.form.idCard !== "") {
            if (!this.regTest.regIdcard.test(this.form.idCard)) {
              this.$toast("请输入正确的身份证号码");
            }
          }
          break;
      }
    },

    // 表单提交
    async handleSubmit() {
      if (this.form.realName === "") {
        this.$toast("请输入姓名");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (this.form.idCard === "") {
        this.$toast("请输入身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (!this.regTest.regIdcard.test(this.form.idCard)) {
        this.$toast("请输入正确的身份证号码");
        this.$refs.container.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      } else if (this.form.credit === "") {
        this.$toast("请选择芝麻分");
        this.$refs.container.scrollTo({
          top: this.$refs.credit.offsetTop,
          behavior: "smooth",
        });
        return;
      }

      this.formItemData.creditType.map((i) => {
        if (i.active) {
          this.form.assets.push(i.dictValue);
        }
      });

      if (this.form.assets.length === 0) {
        this.$toast("请至少选择一项资产");
        return;
      }

      if (!this.positionStatus && this.form.cityId === "") {
        this.$toast("请选择您所在的城市");
        return;
      } else if (!this.checked) {
        this.$toast("请勾选同意授权及告知书");
        return;
      }

      // 按钮loading
      this.loading = true;

      let datas = {
        ...this.form,
        ...{
          realName: setEncryption(this.form.realName),
          idCard: setEncryption(this.form.idCard),
        },
      };

      let res = await API_Common.submitAnEntry(datas);
      this.isTail = res?.data && res.data.isTail ? res.data.isTail : undefined; // 获取此次进件流程是否为兜底
      if (res?.code === 200) {
        // 取消按钮loading
        this.loading = false;

        // 进件申请ID赋值(applyId如果为0则代表进件失败，否则代表成功
        // 1: 贷超流程，失败了要跳转贷超页面
        // 2: 贷超流程，成功了要跳转进件完成页面(并且结果页面按钮展示为<我知道了>),点击我知道了跳转贷超页面
        //进件ID
        this.applyId = res.data.applyId;
        // 产品ID
        this.productId = res.data.productId;
        // 是否推送成功;
        // 1: 贷超流程，失败了要跳转贷超页面
        // 2: 贷超流程，成功了要跳转进件完成页面(并且结果页面按钮展示为<我知道了>),点击我知道了跳转贷超页面
        this.sendData = res.data; //发送给协议弹窗页面
        this.hasPushSuccess = res.data.hasPushSuccess;
        this.applyTime = res.data.applyTime;
        // 重定向页面地址
        this.redirectUrl = res.data.redirectUrl;

        this.incomingShow = true;
        if (this.applyId !== 0 && this.hasPushSuccess) {
          this.companys = res.data.apiNames.join("、");
          this.companyAlias = res.data.apiAlias;
        } else {
          this.companys = "";
        }
      } else if (res?.code === 1109 || res?.code === 1110) {
        // 取消按钮loading
        this.loading = false;

        // 区分贷超流程以及非贷超流程
        // 1109:进件的时候手机号是同一个身份证是同一个重复提交-跳转红包下载页面
        // 1110: 此处判断为进件重复(规则是进件的时候身份证是一个但是手机号不同的情况-跳转红包下载页面)
        this.$toast(res.msg);
        //需要跳转的页面
        this.gotoNextPage(false);
      } else {
        this.loading = false;
        if (res?.msg) {
          this.$toast();
        }
      }
    },
    // 选择芝麻分
    handleChooseCreditCredit(index) {
      this.formItemData.credit.map((item) => {
        item.active = false;
      });
      this.formItemData.credit[index].active = true;
      this.form.credit = this.formItemData.credit[index].dictValue;
      this.$forceUpdate();

      this.$refs.container.scrollTo({
        top: this.$refs.credit.offsetTop,
        behavior: "smooth",
      });
    },

    // 选择信用信息（多选）
    handleSelectCreditInfo(index) {
      // 取消选中
      if (this.formItemData.creditType[index].active) {
        let arr = this.formItemData.creditType.filter((item) => item.active);

        if (arr.length > 1) {
          this.formItemData.creditType[index].active = false;
        } else {
          this.$toast.fail("至少保留一项");
        }
      } else {
        // 选中
        this.formItemData.creditType[index].active = true;
      }
      this.$forceUpdate();
    },

    // 获取省初始化默认传递0默认值省对应下面的城市
    getArea(parentId, index) {
      API_Common.getRegions(parentId).then((res) => {
        if (200 === res.code) {
          this.areaList[index].values = [{ localName: "请选择" }, ...res.data];
          this.areaList = [...this.areaList]; //更新areaList
          this.loading = false;
        } else {
          this.$toast.fail("获取省份失败");
        }
      });
    },

    // 获取省(这个针对百度地图没有返回城市信息的时候，只允许用户操作对应省份下面的城市数据)
    getAreaNotCity(arr) {
      this.areaList[0].values = [{ localName: "请选择" }, ...arr];
      this.areaList = [...this.areaList]; //更新areaList
    },

    // 选择省市下拉选的事件监听
    onCityChange(picker, values, index) {
      if (values[index].localName === "请选择" && index !== 1) {
        this.areaList[1].values = [{ localName: "请选择" }];
      } else {
        if (!index) {
          this.getArea(values[0].id, index + 1); //传参 参数为上层选择的地区的id
        }
      }
    },

    // 地址确认按钮点击
    onAreaConfirm(picker) {
      if (
        picker[0].localName === "请选择" ||
        picker[1].localName === "请选择"
      ) {
        this.$toast.fail("请选择完整的省市");
        return false;
      } else {
        this.form.gpsCityName = `${picker[0].localName} ${picker[1].localName}`;
        this.form.provinceId = picker[0].id;
        this.form.cityId = picker[1].id;
        this.positionStatus = false;
        this.showPickerCity = false;
      }
    },

    // 显示协议
    showProp(type) {
      this.showContent = this[type];
      this.showProtocol = true;
      this.loaclTitle =
        type === "zqgz" ? "《知情告知书》" : "《个人信息共享授权书》";
    },

    // 弹窗关闭时触发
    handleClose() {
      this.showContent = "";
      this.loaclTitle = "";
    },

    // 获取个人信息共享授权书 和 知情告知书 协议内容
    getProtocol() {
      Promise.all([API_Common.getContent(13), API_Common.getContent(14)]).then(
        (res) => {
          this.xxsq = res[0].data.content;
          this.zqgz = res[1].data.content;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 0.1rem;

  .container-top {
    padding: 0.4rem 0.3rem 0;

    > div {
      padding: 0.3rem;
      background: #f6f8ff;
      font-size: 0.24rem;
      border-radius: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #868e9e;

      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.16rem;
      }

      .container-top-title {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: normal;
        color: #111c31;
        margin-bottom: 0.02rem;
      }

      .container-top-amount {
        font-size: 0.4rem;
        font-family: Helvetica, Helvetica-Helvetica;
        font-weight: normal;
        color: #111c31;
      }
    }
  }

  .register-form {
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.4rem 0.3rem 0.3rem;
  }

  .register-form-item {
    margin-bottom: 0.15rem;
    border-bottom: 0.02rem solid #f1f2f6;
    padding: 0.02rem 0;

    &.mb0 {
      margin-bottom: 0;
    }

    .inp-elem {
      padding: 0.2rem 0;
    }

    > h4 {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #111c31;
    }

    /deep/ {
      .van-field__label {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #111c31;
      }

      .van-field__control {
        color: #868e9e;
        font-size: 0.28rem;
      }

      .van-field__button {
        color: #3563fa;
      }
    }

    small {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      color: #868e9e;
      display: block;
      padding-bottom: 0.1rem;
    }

    .citys-tips {
      margin: 0.1rem 0 0.2rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #868e9e;
    }
  }

  .border-none {
    border: none;
  }

  .van-hairline--top-bottom::after {
    display: none;
  }

  .tags {
    font-size: 0.28rem;
    color: #868e9e;
  }

  .van-col {
    > div {
      height: 0.6rem;
      width: 100%;
      margin-top: 0.1rem;
      background: #ffff;
      border: 0.02rem solid #f1f2f6;
      border-radius: 0.12rem;
    }

    > div.mt0 {
      margin-top: 0;
    }
  }

  .tags .active {
    > div {
      background: rgba(53, 99, 250, 0.1);
      border-color: #3563fa;
      color: #3563fa;
    }
  }

  .btn-con {
    background: #fff;
    padding: 0 0.3rem;
    position: sticky;
    bottom: 0;
  }

  .btn {
    width: 100%;
    background: #3563fa;
    border-radius: 0.48rem;
    border: 0;
    font-family: PingFangSC, PingFangSC-Medium;
    font-size: 0.32rem;
    font-weight: 500;
    color: #fff;
    height: 0.96rem;
    line-height: 0.96rem;
    vertical-align: bottom;
    margin: 0.24rem 0 0.3rem;
  }

  .btn-tips {
    left: 47%;
    bottom: 70%;
    height: 0.44rem;
    line-height: 0.44rem;
    padding: 0 0.18rem;
    background: #f44138;
    border-radius: 0.22rem 0.22rem 0.22rem 0;
    font-size: 0.22rem;
    font-family: PingFangSC, PingFangSC-Regular;
    color: #ffffff;
  }

  .protocols {
    padding: 0 0.3rem;
    font-size: 0.24rem;

    /deep/ .van-checkbox__icon {
      margin-top: 0.05rem;
    }
  }

  .protocols-title {
    color: #3563fa;
  }

  .tel-con {
    margin-top: 0.36rem;
    font-size: 0.22rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: normal;
    color: #868e9e;
    > a {
      color: #3563fa;
    }
  }

  .bottom {
    margin: 0.24rem auto;
    font-family: PingFangSC, PingFangSC-Regular;
    font-size: 0.22rem;
    text-align: center;
    color: #c3c2c6;
    line-height: 0.36rem;
    white-space: pre-line;
  }

  .show-con {
    padding: 0 0.4rem;
    height: 100%;
    overflow: hidden;
  }

  .pup-title {
    margin: 0.4rem 0 0.24rem;
    font-size: 0.24rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #868e9e;
    text-align: justify;
  }

  .pup-protocol {
    height: calc(100% - 2.98rem);
    overflow: auto;

    > div {
      height: 45%;
      background: #ebf3fd;
      border-radius: 0.12rem;
      padding: 0.26rem;
      margin-bottom: 0.2rem;
      overflow: hidden;
      transition: height 1s linear;
    }

    > div.expand-height {
      height: auto;
    }

    h4 {
      margin-bottom: 0.2rem;
      font-size: 0.3rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #3c3e41;
    }

    .islink {
      padding: 0.05rem;
      right: 0;
      bottom: 0;
      width: 1.7rem;
      box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
    }
  }

  .go-on {
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.6rem;
    background: #ffffff;
    box-shadow: 0px -0.04rem 0.2rem 0 rgba(0, 0, 0, 0.13);
    padding: 0.16rem 0.4rem 0.48rem;
    font-size: 0.32rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    color: #ffffff;

    > p {
      line-height: 0.96rem;
      background: #3563fa;
      border-radius: 0.48rem;
    }
  }

  .show-protocol-con {
    height: 100%;
    padding: 0.4rem 0.4rem 0;

    > h4 {
      margin-bottom: 0.2rem;
      font-size: 0.3rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      color: #3c3e41;
    }
  }

  .show-protocol-info {
    height: calc(100% - 1.45rem);
    overflow: auto;
  }

  .show-protocol-btn {
    left: 0;
    right: 0;
    border-top: 0.02rem solid #e3e3e3;
    font-size: 0.3rem;
    height: 0.8rem;
    line-height: 0.8rem;
    margin: 0 -0.4rem;
    font-family: PingFangSC, PingFangSC-Regular;
  }

  .picker-title {
    font-size: 0.32rem;

    p.pa {
      left: 0;
      top: 0.88rem;
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #f44138;
      z-index: 2;
      padding: 0 0.25rem;
      text-align: left;
      background: #fff;
    }
  }
}
</style>
